@import "../../../../../assets/scss/variables";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  background-color: $primary-light-50;
  color: $primary-dark-900;
  border-radius: 0.5rem;
  padding: 1.5rem;
  min-height: 27.5rem;
  margin-bottom: 1rem;

  &.success {
    background-color: $s50;
    color: $success-green;

    img {
      width: 6.4375rem;
      height: 6.4375rem;
      flex-shrink: 0;
    }

    span {
      font-weight: 600;
    }
  }

  &.error {
    background-color: $e50;
    color: $e300;

    img {
      width: 6.4375rem;
      height: 6.4375rem;
      flex-shrink: 0;
    }

    a {
      display: inline-flex;
      padding-right: 0.5rem;
      text-decoration: underline;
      color: $e300;
    }

    span {
      max-width: fit-content;
    }
  }

  &.warning {
    background-color: $w50;
    color: $w300;

    img {
      width: 6.4375rem;
      height: 6.4375rem;
      flex-shrink: 0;
    }

    p {
      display: inline;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .infoContainer {
    h4 {
      font-size: 0.875rem;
      font-weight: 700;
      margin-bottom: 0.25rem;
    }

    span {
      font-size: 0.875rem;
    }
  }

  .title {
    font-size: 1rem;
    font-weight: 600;
  }

  ul {
    margin-bottom: 0;

    li {
      font-size: 0.88875rem;
      line-height: 1.5rem;
    }
  }

  .examplesContainer {
    display: flex;
    gap: 1.5rem;
  }

  .validContainer,
  .invalidContainer {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 0.75rem;

    .icon {
      position: absolute;
      top: 0.75rem;
      left: 0.75rem;
    }
  }

  .validContainer {
    border: 1px solid $s300;
  }

  .invalidContainer {
    border: 1px solid $e300;
  }
}

.livenessContainer {
  margin-bottom: 5rem;
}

.livenessQRContainer {
  height: 100vh !important;
  margin: 1.5rem 1.5rem 1rem;

  .successPage {
    padding: 2rem 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;

    h2 {
      color: $primary-normal-300;
      font-size: 1.25rem;
      text-align: center;
    }

    img {
      width: 100%;
      max-width: 440px;
      margin-bottom: 4rem;
    }
  }

  .continueByQRButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0.5rem;
    bottom: 2rem;
    width: calc(100% - 1rem) !important;
    height: 3.2rem;
  }
}

.modalQRSuccessContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  width: 32.5rem;

  img {
    width: 4.5rem;
    height: 4.5rem;
  }

  h3 {
    margin: 1rem 0 0.5rem;
    color: $s300;
    font-size: 1.125rem;
    font-weight: 600;
  }

  span {
    margin-bottom: 2rem;
    color: $primary-dark-900;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.modalQRContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  width: 32.5rem;

  .qrHeader {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    h1 {
      color: $primary-normal-300;
      font-size: 1.125rem;
      font-weight: 600;
      margin: 0;
    }

    span {
      color: var(--allaria-primary-primary-darker-900, #001c40);
      font-size: 0.88875rem;
    }
  }

  .qrContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    .qr {
      margin-left: auto;
      margin-right: auto;
      border-radius: 0.5rem;
      border: 2px solid #224288;
      padding: 0.5rem;

      img {
        width: 11.25rem !important;
        height: 11.25rem !important;
      }
    }
  }
}

.contentCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;

  span {
    max-width: 20rem;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.75rem;
  }
}

@media (max-width: 768px) {
  .container {
    background-color: $white;
    padding: 0;
    column-gap: 1.5rem;
  }

  .infoContainer {
    img {
      display: none;
    }
  }
}
