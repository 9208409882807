@import "../../../assets/scss/variables";

.wrapper {
  position: relative;
  padding: 2.25rem 2.5rem;
  height: 90dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    font-size: 14px;
  }
}

.modal {
  position: relative;
  height: 100%;
}

.header {
  height: 20% !important;
}

.content {
  overflow-y: scroll;
  height: 80% !important;
}

.headerTitle {
  font-weight: 600;
  font-size: 26px;
  color: $primary-dark-900;
}

.footer {
  background-color: white;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  bottom: 0;
  left: 0;

  button {
    position: relative;
    width: 85% !important;
    margin: 0 !important;
  }
}

@media (max-width: 576px) {
  .wrapper {
    height: 100%;
  }

  .content {
    margin-bottom: 2rem !important;
  }
}
