@import "../../../assets/scss/variables";
@import "../../../index.scss";

//Input Checkbox
.FormGroupCheckbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    @extend .form-label;
  }

  span {
    color: $primary-normal-300 !important;
    cursor: pointer;
  }

  .Checkbox {
    display: inline-block;
  }
}

.Checkbox > input {
  border: 2px solid $secondary-normal-300;
  border-radius: 2px !important;
  box-shadow: 0 0 0 !important;
  cursor: pointer;
}

.Checkbox > input:focus {
  border: 2px solid $secondary-normal-300;
}

.Checkbox.lightblue > input:checked {
  background-image: (url("../../../assets/img/Inputs/check.svg")) !important;
  background-color: white;
  border-color: $primary-normal-300;
}

.Checkbox.primary > input:checked {
  background-image: (
    url("../../../assets/img/Inputs/check-red.svg")
  ) !important;
  background-color: white;
  border-color: $primary-normal-300;
}

.Checkbox > label {
  margin-bottom: 0;
}

.inputFile {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(144px, 1fr));
  column-gap: 1.5rem;
  row-gap: 1.25rem;
  font-size: 0.8125rem;
}

.inputFile label {
  color: $primary-dark-900 !important;
  font-size: 0.8125rem;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.attachmentFeedback {
  .svgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    margin-right: 0.3rem;
  }

  label {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.75rem;

    p {
      font-size: 0.75rem;
      margin-bottom: 0;
    }
  }
}

.error {
  margin-left: 0.25rem;
  font-weight: 400;
  font-size: 11px;
  color: $e300;
}

//Input Switch
.InputSwitchGroup {
  text-align: left;

  label {
    color: $primary-dark-900;
    font-weight: 500;
  }
}

.InputSwitch {
  input {
    cursor: pointer;
    height: 1.5rem;
    width: 3rem !important;
    background-color: $secondary-normal-300;
    border: none;
    box-shadow: 0 0 0 !important;
    background-image: url("../../../assets/img/Inputs/switch.svg") !important;

    &:checked {
      background-color: $primary-normal-300;
      border-color: $primary-normal-300;
    }

    &:focus {
      background-image: url("../../../assets/img/Inputs/switch.svg") !important;
    }
  }

  //Input Text/Number
  .FormGroupInputText {
    text-align: left !important;

    label {
      color: $primary-dark-900 !important;
      font-weight: 500;

      span {
        font-weight: normal;
      }
    }

    input {
      box-shadow: 0 0 0 !important;

      &:focus {
        border-color: $primary-dark-900 !important;
      }
    }
  }

  .LabelClickeable {
    color: $primary-normal-300;
    cursor: pointer;
  }
}

.SelectInitialValue {
  color: $secondary-normal-300 !important;
  font-weight: 600;
  text-align: left !important;
  padding-left: 0.5rem;
}

.SelectedValue {
  color: $primary-dark-900 !important;
  font-weight: 600;
  text-align: left !important;
  padding-left: 0.5rem;
}

.CustomOption {
  color: $primary-dark-900;
  text-align: left;
  // height: 1.8rem;
  color: $primary-dark-900;
  padding-left: 1.2rem;
  font-size: 0.875rem;
  font-weight: 400;
  cursor: pointer;

  & p {
    margin: 0;
    padding: 0.5rem 0;
  }

  &:hover {
    background-color: $select-hover;
  }

  & .selectedValue {
    font-weight: 600 !important;
  }
}

.TooltipIcon {
  margin-left: 5px;
  margin-top: -3px;
}

.TooltipIconOptionSelect {
  margin-right: 10rem;
}

.InputSelect {
  position: relative;
}

.HeaderSelect {
  background-color: $primary-dark-900;
  height: 2.3rem;
  border-radius: 0.3rem;
  position: absolute;
  width: 100%;

  p {
    color: white;
    font-size: 1.5rem;
    margin-left: 1rem;
    font-weight: bold;
    text-align: left;
  }
}

.InputTel {
  color: $primary-dark-900;
  border: 1px solid $primary-light-50;
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 0.3rem 1.25rem;
  display: flex;
  background-color: $white;

  &.borderBlack {
    border-color: $primary-dark-900 !important;
  }

  &.borderError {
    border-color: $e300 !important;
  }

  &.borderSuccess {
    border-color: $s300 !important;
  }

  &.borderDefault {
    border-color: $primary-light-50;
  }
}

.InputTel input {
  border: none;
  font-size: 0.875rem;

  &::placeholder {
    font-weight: 500;
    color: $secondary-light-200;
  }

  &:first-child {
    width: 3.5rem;
  }

  &:last-child {
    border-left: 1px solid #a6a6be !important;
    padding-left: 1rem;
    width: 100%;
  }

  &:focus-visible {
    outline: none;
  }
}

.FormGroupInputText {
  label {
    font-weight: 500;
  }

  .icon {
    width: 0.875rem !important;
    margin: 0.625rem 0.625rem 0.625rem 0 !important;
  }
}

//Arrows of inputs number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.icon {
  width: 0.875rem !important;
  margin: 0.625rem 0.625rem 0.625rem 0 !important;
}

.autoCompleteOptions {
  background: #ffffff;
  border: 1px solid #e3ebf6;
  border-radius: 4px;
  padding: 0.4rem 0;
  position: absolute;
  margin-top: 0.5rem;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 4px 11px hsla(0, 0%, 0%, 0.1);
  z-index: 1;

  p {
    padding: 0.4rem 1rem;
    color: $primary-dark-900;
    font-size: 0.875rem;
    line-height: 1.5rem;
    cursor: pointer;
    margin: 0;
  }

  .active {
    background: #c4c4c44d;
  }
}

.autoComplete {
  position: relative;
}

@media (max-width: 768px) {
  .inputFile {
    width: auto;
  }
}
