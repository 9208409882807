.right-to-left-enter {
  transform: translateX(100%);
}
.right-to-left-enter-active {
  transform: translateX(0);
  transition: all 1s ease;
}

.right-to-left-exit {
  transform: translateX(0);
}
.right-to-left-exit-active {
  transform: translateX(-100%);
  transition: all 1s ease;
}

.left-to-right-enter {
  transform: translateX(-100%);
}
.left-to-right-enter-active {
  transform: translateX(0);
  transition: all 1s ease;
}

.left-to-right-exit {
  transform: translateX(0);
}
.left-to-right-exit-active {
  transform: translateX(100%);
  transition: all 1s ease;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.content {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 40rem;
  opacity: 0;
}
.contentBis {
  overflow-y: scroll;
  overflow-x: hidden;
  opacity: 0;
}

@media (max-width: 768px) {
  .content {
    overflow-y: auto;
  }
}
