@import "../../../assets/scss/variables";

.stepOne {
  padding: $container-padding;
  // margin-top: 1rem;

  .checkBox {
    // padding-left: 0.125rem !important;
    margin-top: -1rem;
    margin-bottom: 0.5rem;
  }

  .referredInput {
    padding-top: 6px !important;
  }

  .referredSpan {
    color: $secondary-normal-300;
    font-size: 0.75rem;
    margin-top: -1.5rem !important;
  }

  .referredSpanErr {
    color: $secondary-normal-300;
    font-size: 0.75rem;
  }

  .footer {
    color: $secondary-normal-300;
    font-size: 0.75rem;

    span {
      color: $primary-normal-300;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        color: $primary-dark-900;
      }
    }
  }
}

//Step three

.stepThree {
  // margin-top: 2rem;
  padding: $container-padding;

  .secondLabel {
    color: $primary-normal-300;
    cursor: pointer;
  }

  .subTitleContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    h3 {
      color: $dark-blue;
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

  .tooltipAndExample {
    margin-left: 0.5rem;
    font-weight: 500;

    span {
      color: $primary-normal-300 !important;
      cursor: pointer;
      font-size: 0.85rem;
      padding-left: 0.1rem;
    }
  }

  .dniRow {
    label {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      color: $primary-dark-900;
      margin-bottom: 0.75rem;
      font-size: 0.875rem;
      margin-top: 1.25rem;

      h3 {
        color: $dark-blue;
        font-size: 1rem;
        margin-bottom: 0;
      }
    }
  }

  .dniWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .contact {
    font-weight: 500;
    font-size: 0.75rem;
    color: $secondary-normal-300;

    b {
      color: $primary-dark-900;
    }
  }

  button {
    font-size: 0.8125rem;
  }
  .zipCodeInfo,
  .zipCodeInfoErr {
    display: flex;
    font-weight: 400;
    font-size: 0.75rem;
    color: $primary-normal-300;
    justify-content: start;
    align-items: start;
    margin-top: -1.5rem;

    svg {
      margin-right: 0.4125rem;
      width: 0.875rem;
    }

    a {
      color: $primary-normal-300;
      text-decoration: none;
    }

    span {
      text-align: left;
    }
  }

  .zipCodeInfoErr {
    margin-top: 0.3rem !important;
  }

  .labelLink {
    margin-bottom: -1rem;
    font-weight: 500;
    color: $primary-dark-900;

    a {
      color: $primary-normal-300;
      font-weight: 400;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .link {
    cursor: pointer;
    color: $primary-normal-300;
  }
}

  .QRContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.75rem;

    p {
      margin-bottom: 0;
      margin-left: 0.75rem;
      font-size: 0.9rem;
      color: $secondary-normal-400;
    }
  }

.infoContainer {
  h4 {
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.25rem;
  }

  span {
    font-size: 0.875rem;
  }
}

@media (max-width: 992px) {
  .dniRow {
    flex-direction: column;
    column-gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .dniWrapper {
    flex-direction: row;
    column-gap: 1.5rem;
  }
}

// Step Four
.stepFour {
  padding: $container-padding;
  .StepFourRow {
    align-items: end;

    .StepFourCol {
      margin-top: 1rem;
    }
  }
}

@media (max-width: 992px) {
  .StepFourRow > Col {
    margin-top: 2rem;
  }
}

// Step Five
.stepFive {
  padding: $container-padding;

  .description {
    font-size: 0.9rem;
    color: $secondary-normal-300;
    font-weight: 500;
  }

  .subtitle {
    font-size: 1rem;
    font-weight: 500;
    color: $primary-dark-900;
    margin-bottom: 2rem;
  }

  .text {
    position: absolute;
    font-size: 0.7rem;
    font-weight: 400;
    color: $primary-dark-900;
    margin-top: -1.5rem;
  }
  a {
    display: inline-block;
  }
}

.buttonsRow {
  margin-bottom: 4.5rem;
}

.buttonsRowStepFive {
  margin-bottom: 8.5rem;
}
