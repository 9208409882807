@import "../../../assets/scss/variables";

.ContainerTooltip {
  padding: 5px 10px;

  p {
    margin: 0;
  }

  ul {
    margin: 0;
    padding-left: 1.5rem;
  }
}

.TextLink {
  color: $primary-normal-300;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

.Text {
  color: $primary-dark-900;
  font-weight: 400;
}
