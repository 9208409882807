@import "../../../assets/scss/variables";

.button {
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  height: 2.25rem;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  line-height: 1rem;
  font-size: 0.875rem;
  font-weight: 500;

  &:focus {
    outline: none !important;
  }

  &:hover {
    .buttonContent svg path {
      stroke: white;
    }
  }

  .buttonContent {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    p {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1px;
      margin-bottom: 0;
    }

    svg {
      margin-left: 0.5rem;
      margin-bottom: 0;
    }
  }
}

//Button Types
.primary {
  @extend .button;
  background-color: $primary-normal-300;
  border: 1px solid $primary-normal-300;
  color: white;

  &:hover {
    &:not([disabled]) {
      background-color: darken($primary-normal-300, 5%);
      border-color: darken($primary-normal-300, 5%);
    }
  }

  &:disabled {
    background-color: $secondary-light-200;
    border-color: $secondary-light-200;
    cursor: default;
  }
}

.secondary {
  @extend .button;
  background-color: transparent;
  border: 1px solid $primary-normal-300;
  color: $primary-normal-300;

  &:hover {
    &:not([disabled]) {
      background-color: $primary-normal-300;
      color: white;
    }
  }

  &:disabled {
    border: 1px solid $secondary-light-200;
    color: $secondary-light-200;
    cursor: default;
  }
}

.secondary.error {
  border: 1px solid $e300;
  color: $e300;

  &:hover {
    &:not([disabled]) {
      background-color: $e300;
      color: white;
    }
  }
}

.tertiary {
  @extend .button;
  color: $primary-normal-300;
  text-decoration: underline;

  &:hover {
    color: $primary-normal-400;
  }

  &:disabled {
    pointer-events: none;
    color: $secondary-light-200;
    cursor: default;
  }
}

.primaryLoading {
  @extend .primary;

  &:disabled {
    background-color: $primary-normal-300;
    border: 1px solid $primary-normal-300;
  }
}

.secondaryLoading {
  @extend .secondary;

  &:disabled {
    background-color: transparent;
    border: 1px solid $primary-normal-300;
  }
}

.tertiaryLoading {
  @extend .tertiary;
}

//Loading Circle
#circleG {
  width: 60px;
  margin: auto;
}

.circleG {
  background-color: $white;
  float: left;
  height: 11px;
  margin-left: 7px;
  width: 11px;
  animation-name: bounce_circleG;
  -o-animation-name: bounce_circleG;
  -ms-animation-name: bounce_circleG;
  -webkit-animation-name: bounce_circleG;
  -moz-animation-name: bounce_circleG;
  animation-duration: 2.24s;
  -o-animation-duration: 2.24s;
  -ms-animation-duration: 2.24s;
  -webkit-animation-duration: 2.24s;
  -moz-animation-duration: 2.24s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
  border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}

#circleG_1 {
  animation-delay: 0.45s;
  -o-animation-delay: 0.45s;
  -ms-animation-delay: 0.45s;
  -webkit-animation-delay: 0.45s;
  -moz-animation-delay: 0.45s;
}

#circleG_2 {
  animation-delay: 1.05s;
  -o-animation-delay: 1.05s;
  -ms-animation-delay: 1.05s;
  -webkit-animation-delay: 1.05s;
  -moz-animation-delay: 1.05s;
}

#circleG_3 {
  animation-delay: 1.35s;
  -o-animation-delay: 1.35s;
  -ms-animation-delay: 1.35s;
  -webkit-animation-delay: 1.35s;
  -moz-animation-delay: 1.35s;
}

@keyframes bounce_circleG {
  0% {
  }

  50% {
    background-color: $primary-normal-300;
  }

  100% {
  }
}

@-o-keyframes bounce_circleG {
  0% {
  }

  50% {
    background-color: $primary-normal-300;
  }

  100% {
  }
}

@-ms-keyframes bounce_circleG {
  0% {
  }

  50% {
    background-color: $primary-normal-300;
  }

  100% {
  }
}

@-webkit-keyframes bounce_circleG {
  0% {
  }

  50% {
    background-color: $primary-normal-300;
  }

  100% {
  }
}

@-moz-keyframes bounce_circleG {
  0% {
  }

  50% {
    background-color: $primary-normal-300;
  }

  100% {
  }
}

.leftMarginButton {
  margin-left: 1.5rem;
}
