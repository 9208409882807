/*THEME BACKGROUND WHITE AND BORDER BLUE */

.tippy-box[data-theme~="white-border"] {
  background-color: white;
  color: black;
  border: 1px solid #001c40;
}

.tippy-box[data-theme~="white-border"][data-placement^="top"]
  > .tippy-arrow::before {
  border-top-color: white;
}
.tippy-box[data-theme~="white-border"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: white;
}
.tippy-box[data-theme~="white-border"][data-placement^="left"]
  > .tippy-arrow::before {
  border-left-color: white;
}
.tippy-box[data-theme~="white-border"][data-placement^="right"]
  > .tippy-arrow::before {
  border-right-color: white;
}
