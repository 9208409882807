// $primary-color: #F34202;
$dark-blue: #000046;
$light-blue: #2981cc;
$gray: #a6a6be;
$soft-gray: #e3ebf6;
$white: #ffffff;
$green-success: #00a310;
$red-error: #ff3344;
$select-hover: #c4c4c44d;
$container-padding: 0 2rem 0.3rem 0.3rem;

//ALLARIA + COLOR SYSTEM

//PRIMARY SCALE
$primary-light-50: #e6eef8;
$primary-light-100: #d9e5f4;
$primary-light-200: #b0c9e9;
$primary-normal-300: #0150b7;
$primary-normal-400: #0148a5;
$primary-normal-500: #014092;
$primary-dark-600: #013c89;
$primary-dark-700: #01306e;
$primary-dark-800: #002452;
$primary-dark-900: #001c40;

//SECONDARY SCALE
$secondary-light-50: #ecedf0;
$secondary-light-100: #d1d5db;
$secondary-light-200: #acb3bf;
$secondary-normal-300: #8690a1;
$secondary-normal-400: #626f84;
$secondary-normal-500: #3f4f69;
$secondary-dark-600: #364359;
$secondary-dark-700: #2d384b;
$secondary-dark-800: #242d3c;
$secondary-dark-900: #1c242f;

//ACCENT SCALE
$accent-light-50: #f9f6ed;
$accent-light-100: #f6f2e4;
$accent-light-200: #ede3c8;
$accent-normal-300: #c6a64e;
$accent-normal-400: #b29546;
$accent-normal-500: #9e853e;
$accent-dark-600: #957d3b;
$accent-dark-700: #77642f;
$accent-dark-800: #594b23;
$accent-dark-900: #453a1b;

//GREY SCALE
$white: #ffffff;
$greyscale-50: #fcfcfc;
$greyscale-100: #f5f5f5;
$greyscale-200: #f0f0f0;
$greyscale-300: #d9d9d9;
$greyscale-400: #bfbfbf;
$greyscale-500: #8c8c8c;
$greyscale-600: #595959;
$greyscale-700: #454545;
$greyscale-800: #262626;
$greyscale-900: #1f1f1f;
$greyscale-950: #141414;
$black: #000000;

//ERROR SCALE
$e50: #f9e8e9;
$e75: #e5a2a6;
$e100: #da7c81;
$e200: #ca434b;
$e300: #bf1d26;
$e400: #86141b;
$e500: #751217;

//WARNING SCALE
$w50: #fcf6e8;
$w75: #f1dc9f;
$w100: #eccd78;
$w200: #e4b83e;
$w300: #dea916;
$w400: #9b760f;
$w500: #87670d;

//SUCCESS SCALE
$s50: #e8f5ed;
$s75: #a1d7b6;
$s100: #7ac798;
$s200: #40ae6c;
$s300: #199e4e;
$s400: #126f37;
$s500: #0f6030;

//INFO SCALE
$i50: #e9f3f9;
$i75: #a3cce5;
$i100: #7db7da;
$i200: #4497cb;
$i300: #1e82c0;
$i400: #155b86;
$i500: #124f75;

$black2: #1f2d3d;
$black3: #12263f;
$mid-black: #1f2d3d;
$light-black: #41416b;
$grey: #e5e5e5;
$grey2: #e3ebf6;
$grey3: #d3d3df;
$green: #00a310;
$light-green: #9ec6ac;
$middle-grey: #babac7;
$middle-grey2: #a6a6be;
$middle-grey3: #d3d3df;
$dark-grey: #787880;
$muted-grey: #95aac9;
$muted-grey2: #6e84a3;
$soft-grey: #a6a6be;
$white: #ffffff;
$primary-color: #f34202;
$error-color: #e63757;
$error-color2: #d20000;
$error-color3: #ff3344;
$light-blue: #b1c2d9;
$brown: #bd8800;
$light-brown: #c8a85a;
$error-alert-border: #ff3344;
$success-alert-border: #0fbd00;
$success-alert-border2: #04af0b;
$error-color2: #d20000;
$brown: #bd8800;
$warning-alert-border: #ffa132;
$success-green: #37aa57;
$user-owner: #e76f51;
$user-admin: #2a9d8f;
$user-contributor: #336cb9;
$user-read-only: #e9c46a;
$owner-color: #e76f51;
$owner-color-dark: #b55740;
$admin-color: #2a9d8f;
$admin-color-dark: #1f776c;
$regular-color: #336cb9;
$regular-color-dark: #244d86;
$watcher-color: #e9c46a;
$watcher-color-dark: #c8a85a;
$signer-color: #ac3350;
$signer-color-dark: #87283f;
$warning: #ecb90d;
$hover-2: #f56835;
$focus-2: #b03701;
$select-hover-gray: #eeeeee;
$helper-text: #2981cc;
$mid-blue: #336cb9;
