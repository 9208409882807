@import "../../../assets/scss/variables";

.background {
  width: 100%;
  padding: 3.5rem;
  min-height: 100dvh;
}

.stepper {
  grid-area: stepper;
  margin-top: 10px;
}

.form {
  grid-area: form;
}

.header {
  grid-area: header;
}

.footer {
  grid-area: footer;
  font-weight: 500;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;
  margin-top: 1.5rem;

  p {
    color: $primary-dark-900;
  }

  a {
    color: $primary-normal-300;
    text-decoration: none;
    display: inline-block;
    &:hover {
      color: $primary-dark-900;
    }
    margin-left: 0.25rem;
  }
  span {
    font-size: 0.875rem;
    color: $secondary-normal-300;
  }
}

.daruma {
  grid-area: daruma;
  height: 50px;
  width: 100%;
}

.wrapperGrid {
  background-repeat: no-repeat;
  display: grid;
  width: 100%;
  height: 100%;
  max-width: 66rem;
  margin: auto;
  column-gap: 5rem;
  grid-template-areas:
    "daruma header"
    "stepper  form"
    "footer  form";
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 4rem;
  a {
    display: flex;
    justify-self: flex-start;
  }
}

.toolbar {
  display: none;
}

@media (max-width: 768px) {
  .background {
    padding: 0;
    background-image: none !important;
  }
  .wrapperGrid {
    padding: 2rem;
    grid-template-areas:
      "stepper"
      "header"
      "form"
      "footer";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 2rem;

    a {
      display: block;
    }
  }
  .daruma {
    display: none;
  }

  .footer {
    text-align: center;
  }

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-dark-900;
    width: 100%;
    height: 5rem;
  }
}

.linkTo {
  margin-left: 0 !important;
  color: $secondary-normal-300 !important;
  white-space: nowrap;
  &:hover {
    color: $primary-normal-300 !important;
  }
}
