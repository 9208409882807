/* modal */
.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 1500;
    display: flex;
    overflow-y: scroll;
    padding: 0.5rem 1rem;
    flex-direction: column;
}

.modalWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;
}
  
.modalCard{
  position: relative !important;
  background-color: white;
  border-radius: 0.5rem;
  height: fit-content;
  width: fit-content;
}

.closeIcon{
  position: absolute !important;
  cursor: pointer;
  z-index: 1;
} 

.contentWrapper{
  position: relative;
}

.backdropMobile{
  padding: 0;
  padding-top: 88px;
  z-index: 1;
}

.modalWrapperMobile{
  margin: 0;
  flex-grow: 1;
}

.modalCardMobile{
  height: 100%;
  width: 100%;
  border-radius: 0;
}

@media (max-width: 478px) {
  .closeIcon{
    right: 18px;
    top: 10px;
  }
}

@media(max-width: 768px){
  .backdrop{
    padding: 0;
    z-index: 1;
  }
  .modalWrapper{
    margin: 0;
    flex-grow: 1;
  }
  .modalCard{
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
}