@import "../../assets/scss/variables";

.background {
  width: 100%;
  padding: 5rem;
  min-height: 100dvh;
  display: flex;
  justify-content: center;
}
.successContainer {
  max-width: 40rem !important;

  .logo {
    height: 5rem;
    margin-bottom: 4.3rem;
  }

  h3 {
    color: $primary-dark-900;
    font-size: 1.25rem;
  }

  .description {
    color: $secondary-normal-300;
    font-size: 1rem;
    text-align: center;
  }

  .footer {
    font-size: 0.87rem;
    line-height: 1.3rem;
    color: $secondary-normal-300;
    margin-top: 4rem;
    text-align: center;

    span {
      white-space: nowrap;
    }
  }
  .button {
    font-size: 1rem;
    margin: 0 0.5rem;
  }
}

.toolbar {
  display: none;
}

@media (max-width: 768px) {
  .background {
    background-image: none !important;
    display: block;
    padding: 0;
  }

  .successContainer {
    padding: 3rem 2rem 2rem 2rem;
  }

  .footer {
    margin-top: 2rem !important;
    font-size: 0.75rem !important;
  }

  .button {
    margin: 0;
    font-size: 0.813rem !important;
  }

  .description {
    font-size: 0.813rem !important;
  }

  .logo {
    display: none;
  }

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-dark-900;
    width: 100%;
    height: 5rem;
  }
}
