@import "../../assets/scss/variables";

.toastMessage {
  color: $primary-dark-900;
  font-weight: 14px;
}

.contactText {
  color: $primary-normal-300;
  text-decoration: none;

  &:hover {
    color: $primary-normal-300;
  }
}
