@import "../../../assets/scss/variables";

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 2rem !important;
  color: $primary-dark-900;
  .title {
    font-weight: 600;
    font-size: 1.5rem;
    width: 100%;
  }

  .description {
    font-weight: 400;
    font-size: 1rem;
    padding-bottom: 1rem;
  }
}
