@import "../../../assets/scss/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0 0.3rem;
}

.titleStyles {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.875rem;
  color: $primary-dark-900;
  margin-bottom: 0;
}

.arrowStyles {
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}

.dotStyles {
  cursor: pointer;
}

@media (max-width: 768px) {
  .titleStyles {
    font-size: 1rem;
  }
}
