@import "../../../assets/scss/variables";

.circle {
  padding: 0.4rem;
}

.stepper {
  text-align: left !important;

  .line {
    width: 0.125rem;
    height: 100%;
    background-color: $primary-light-50 !important;
  }

  .active {
    .circle {
      padding: 0.3rem;
      background-color: $white !important;
      border: 0.1rem solid $primary-normal-300;
    }
  }

  .fill {
    .circle {
      background-color: $primary-normal-300;
    }
  }

  .disabled {
    color: $secondary-light-200;

    .circle {
      background-color: $secondary-light-200;
    }
  }

  p {
    font-size: 1rem;
    margin: 0px;
  }

  .lastStep:last-child {
    .line {
      display: none !important;
    }
  }
}

.text {
  color: $primary-dark-900;
  font-size: 1rem;
}

.textActive {
  color: $primary-dark-900;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 3.125rem !important;
}

.textDisabled {
  color: $secondary-light-200;
  font-size: 1rem;
}

.prevStep {
  cursor: pointer;
}

@media (max-width: 767px) {
  .stepper {
    .line {
      height: 0.125rem;
      width: 1.875rem;
      margin-left: 0.7rem;
    }
  }
}
