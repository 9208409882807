@import "../../../assets/scss/variables";

.inputBox {
  width: 2.125rem;
  height: 2.125rem;
  padding: 7px;
  text-align: center;
  border-radius: 4px;
  background: white;
  border: 1px solid #e3ebf6;

  // on focus border color primary
  &:focus {
    border-color: $primary-normal-300 !important;
  }
  &:focus-visible {
    outline: none !important;
  }
  // not last input
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
}
