@import "../../../assets/scss/variables";

.container {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  padding: 0.75rem 1rem;
  background-color: $i50;
  border-radius: 0.5rem;
  color: $primary-dark-900;

  &.darkStyle {
    color: $i50;
    background-color: $primary-dark-900;
  }

  .icon {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
  }

  .alertText {
    font-size: 0.875rem;
  }
}