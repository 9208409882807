@import "../../assets/scss/variables";

.dniUpload {
  height: 100%;
  overflow: scroll;
  padding: 2rem 1.5rem;

  h3 {
    color: $dark-blue;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  label {
    color: $secondary-dark-900;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  .tips {
    margin-top: 2rem;
    font-size: 0.875rem;
    color: $secondary-normal-500;

    b {
      font-weight: 600;
    }
  }
}

.readyButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0.5rem;
  bottom: 2rem;
  padding: 1.5rem;
  width: calc(100% - 1rem) !important;
}

.successPage {
  padding: 2rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;

  h2 {
    color: $primary-normal-300;
    font-size: 1.25rem;
    text-align: center;
  }

  img {
    width: 100%;
    max-width: 440px;
    margin-bottom: 4rem;
  }
}
