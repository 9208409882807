@import "../../../assets/scss/variables";

.errorMessage {
  display: flex;
  align-items: center;
  margin-top: 0.625rem;

  span {
    font-size: 0.75rem;
    color: $e300;
  }

  svg {
    margin-right: 0.5rem;
  }
}

.button {
  font-size: 15px;
}

.inputTokenGroup {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.description {
  font-size: 16px;
}

.bodyWrapper {
  text-align: left !important;
  color: $primary-dark-900;

  h3 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
  }
}

.inputToken {
  min-height: 4rem;
}

@media (max-width: 768px) {
  .bodyWrapper {
    h3 {
      text-align: center;
      font-size: 1rem;
    }

    p {
      text-align: justify;
      margin-bottom: 2rem;
      font-size: 0.75rem;
    }

    .button {
      font-size: 0.861rem;
    }

    .buttonContainer {
      width: 100%;
      text-align: center;
      .button {
        font-size: 0.861rem;
      }
    }
  }
}
