@import "../../../assets/scss/variables";

.dniContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem !important;
  img {
    width: 18.75rem !important;
  }
  .title {
    font-weight: 600;
    color: $primary-dark-900;
    font-size: 1.625rem;
  }

  .subtitle {
    font-weight: 500;
    color: $primary-dark-900;
    font-size: 0.8125rem;
  }

  .imageSubtitle {
    width: 3rem !important;
    margin-bottom: 0.5rem;
  }

  .description {
    font-weight: 400;
    color: $secondary-normal-300;
    font-size: 0.75rem;
  }
}
