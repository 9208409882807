.errorContainer {
  height: 100dvh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;

  h1 {
    font-size: 1.3rem;
    font-weight: bolder;
    margin-top: 1.5rem;
    text-align: center;
  }

  .text {
    font-size: 1rem;
    color: #a6a6be;
    width: 30rem;
    text-align: center;
    margin-top: 0.6rem;
  }

  button {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
}
