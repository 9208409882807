@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import "./assets/scss/variables";

* {
  font-family: "Poppins", sans-serif;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

$theme-colors: (
  "primary": $primary-normal-300,
  "secondary": $primary-dark-900,
  "success": $s300,
  "light-blue": $light-blue,
  "gray": $gray,
  "soft-gray": $primary-light-50,
  "white": $white,
  "error": $e300,
);

@import "~bootstrap/scss/bootstrap";

.custom-form-group .form-control:focus {
  border-color: $primary-dark-900 !important;
  box-shadow: none;
}
.custom-form-group .form-control:invalid,
.custom-form-group .form-control.is-invalid {
  border-color: $e300 !important;
  background-image: none !important;
  padding-right: 0.75rem !important;
  &:disabled {
    border-color: $soft-gray !important;
  }
}

.custom-form-group .form-control.is-valid {
  border-color: $s300 !important;
  background-image: none !important;
  padding-right: 0.75rem !important;
  &:disabled {
    border-color: $soft-gray !important;
  }
}
.form-control {
  border-color: $primary-light-50 !important;
}

.react-select-container {
  color: red !important;
}
.myselect {
  &__control {
    border-color: $soft-gray !important;
    &--is-focused {
      border-color: $primary-dark-900 !important;
      box-shadow: none !important;
    }
  }
}
.is-valid {
  .myselect {
    &__control {
      border-color: $s300 !important;
      &--is-focused {
        border-color: $primary-dark-900 !important;
      }
    }
  }
}
.is-invalid {
  .myselect {
    &__control {
      border-color: $e300 !important;
      &--is-focused {
        border-color: $primary-dark-900 !important;
      }
    }
  }
}

//Generics styles of inputs
.invalidField {
  color: $e300;
  font-size: 0.75rem;
}
.invalid-feedback {
  //if you want edit, go to extend class
  @extend .invalidField;
}
// All of bootstrap label
.form-label {
  color: $primary-dark-900;
  font-size: 0.875rem;
  font-weight: 500 !important;
}
.form-control {
  font-size: 0.875rem;
  font-weight: 400;
  &::placeholder {
    font-weight: 500;
    color: $secondary-light-200 !important;
  }
}

h5 {
  color: $primary-dark-900;
}

.text-gray {
  color: $secondary-normal-300;
}

.amplify-liveness-toast--primary {
  border-radius: 0.5rem !important;
  background-color: $white !important;
}
.amplify-liveness-toast--error {
  border-radius: 0.5rem !important;
  background-color: $e50 !important;
  color: $e300 !important;

  .amplify-liveness-toast__message {
    color: $e300 !important;
    font-size: 1.26rem !important;
  }
}

.amplify-liveness-toast__message {
  color: $primary-normal-300 !important;
  font-size: 1.26rem !important;
  padding: 0.125rem 0.75rem !important;
  font-weight: 600 !important;
}

.amplify-button--primary {
  width: 100% !important;
  background-color: $primary-normal-300 !important;
  color: $white;
  font-weight: 500 !important;
  border-radius: 0.5rem !important;
}

.liveness-detector-check {
  gap: 1rem !important;

  .amplify-liveness-recording-icon-container {
    display: none !important;
  }
}

.amplify-liveness-cancel-container {
  display: none !important;
}

.amplify-liveness-start-screen-warning {
  display: none !important;
}
.amplify-liveness-match-indicator__bar::after {
  background-color: $primary-normal-300 !important;
}

.liveness-detector-check {
  gap: 0 !important;
}

.liveness-detector,
.liveness-detector-check div {
  p {
    font-weight: 500 !important;
  }
}
