@import "../../../../../assets/scss/variables";

.modalContainer {
  padding: 1.5rem;

  .title {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }

  .title,
  a {
    color: $primary-normal-300;
  }

  .subtitle {
    font-weight: 600;
    margin-bottom: 0;
  }

  .description,
  .infoAlertClassName,
  ul {
    margin-bottom: 1.5rem;
  }

  .subtitle,
  .description,
  li {
    color: $primary-dark-900;
    font-size: 0.88875rem;
    line-height: 1.5rem;
  }

  a {
    width: fit-content;
  }
}
